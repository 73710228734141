import PromiseActuator from '../utils/PromiseActuator'

let actuator = new PromiseActuator()

export const usePitProduct = () => {
  const setPitProductsInfo = async (data) => {
    let feedsAddItemsInfo = data
    if (actuator.state !== 'pending') actuator = new PromiseActuator()

    let res

    const addItemList = feedsAddItemsInfo?.addItemList || []

    if (!addItemList.length) {
      res = null
      actuator.resolve(res)
      return res
    }

    res = feedsAddItemsInfo
    
    actuator.resolve(res)
    return res
  }

  const getPitProductsInfo = async ({ reset } = {}) => {
    if (reset) resetPitProduct()
    const res = await actuator.promise
    return res
  }

  const resetPitProduct = () => {
    actuator = new PromiseActuator()
  }

  return {
    resetPitProduct,
    setPitProductsInfo,
    getPitProductsInfo,
  }
}
