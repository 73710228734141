class PromiseActuator {
  constructor() {
    this.state = 'pending'
    this.promise = null
    this.resolve = null

    this.init()
  }

  init() {
    this.promise = new Promise(r => {
      this.resolve = (d) => {
        this.state = 'fulfilled'
        r(d)
      }
    })
  }
}

export default PromiseActuator
